import React, { useContext } from 'react';
import { DeleteIcon } from '../icons/DeleteIcon';
import { AuthContext } from './AuthContext';
import { DownloadIcon } from '../icons/DownloadIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Formatter = (value: any) => string; // TODO: support ReactNode here

const defaultFormatters: Record<string, Formatter> = {
  string: (value: string) => value,
  number: (value: number) => value.toString(),
  undefined: () => '',
  null: () => '',
};

export interface ColumnDefinition {
  title: string;
  index: number;
  formatter?: Formatter;
}

type CellValueType = string | number | null; // add more as needed (object?)

export class RowType {
  [key: string]: CellValueType;
}

export type GenericTableProps<R extends RowType> = {
  rows: R[];
  getPkOfRow: (row: R) => string;
  deleteCallback?: (token: string, pk: string) => Promise<void>;
  downloadLink?: (row: R) => Promise<void>;
};

export function GenericTable<R extends RowType>(
  columnDefs: Record<string, ColumnDefinition>,
): React.FC<GenericTableProps<R>> {
  const columnProjection = (
    columnDefs: Record<string, ColumnDefinition>,
    showDeleteColumn: boolean,
    showDownloadColumn: boolean,
  ) => {
    const cols: [string, ColumnDefinition][] = Object.entries(columnDefs);
    cols.sort(
      (a: [string, ColumnDefinition], b: [string, ColumnDefinition]) =>
        a[1].index - b[1].index,
    );
    const deleteColumn: [string, ColumnDefinition] = [
      'delete',
      { title: 'Delete', index: 9998 },
    ];

    const downloadColumn: [string, ColumnDefinition] = [
      'download',
      { title: 'Download File', index: 9999 },
    ];

    const result: [string, ColumnDefinition][] = cols;
    if (showDeleteColumn) {
      result.push(deleteColumn);
    }
    if (showDownloadColumn) {
      result.push(downloadColumn);
    }
    return result;
  };
  const rowProjection = (row: R) => {
    // filter out columns that are not in the columns parameter
    const entries: [string, CellValueType][] = Object.entries(row).filter(
      ([key]) => columnDefs[key],
    );
    entries.sort(
      (a: [string, CellValueType], b: [string, CellValueType]) =>
        columnDefs[a[0]].index - columnDefs[b[0]].index,
    );
    return entries;
  };

  const GenericTableFunction = ({
    rows,
    getPkOfRow,
    deleteCallback,
    downloadLink,
  }: GenericTableProps<R>) => {
    const authContext = useContext(AuthContext);
    const { token } = authContext;

    const showDeleteColumn: boolean = deleteCallback !== undefined;
    const showDownloadColumn: boolean = downloadLink !== undefined;

    return (
      <div className="overflow-x-auto">
        <table className="table table-zebra">
          <thead>
            <tr>
              {columnProjection(
                columnDefs,
                showDeleteColumn,
                showDownloadColumn,
              ).map(([key, column]) => (
                <th key={key} className="text-center">
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row: R, i: number) => (
                <tr key={i}>
                  {rowProjection(row).map(([key, value]) => {
                    const column = columnDefs[key];
                    const formatter =
                      column.formatter || defaultFormatters[typeof value];
                    const formatted = formatter(value);
                    return <td key={key}>{formatted}</td>;
                  })}

                  {showDeleteColumn && (
                    <td key={`delete-column_${i}`} className="text-center">
                      <button
                        className="inline-flex items-center justify-center"
                        data-testid={`delete-button_${i}`}
                        onClick={() => {
                          deleteCallback &&
                            deleteCallback(token, getPkOfRow(row));
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  )}
                  {showDownloadColumn && (
                    <td key={`download-column_${i}`} className="text-center">
                      <button
                        className="inline-flex items-center justify-center"
                        onClick={() => {
                          downloadLink && downloadLink(row);
                        }}
                      >
                        <DownloadIcon />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };
  return GenericTableFunction;
}
