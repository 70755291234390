import React from 'react';
import './App.css';
import { AuthProvider } from './reusable-components/AuthContext';
import { RouterComponent } from './reusable-components/RouterComponent';
import { CurrentPageProvider } from './reusable-components/CurrentPageContext';

const App = () => {
  return (
    <AuthProvider>
      <CurrentPageProvider>
        <div className="p-4">
          <RouterComponent />
        </div>
      </CurrentPageProvider>
    </AuthProvider>
  );
};

export default App;
