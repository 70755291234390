import React, { useState, useContext } from 'react';
import { ColumnDefinition } from '../reusable-components/GenericTable';
import { PaginatedTable } from '../reusable-components/PaginatedTable';
import { AuthContext } from '../reusable-components/AuthContext';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { Link, useNavigate } from 'react-router-dom';
import { isBlank, isNumeric } from '../utils/common-functions';
import {
  checkTokenAndRenderCode,
  isTokenValid,
} from '../utils/common-functions';
import { CompanySearchResult } from '../models/company/CompanySearchResult';
import { TextInput } from '../reusable-components/TextInput';
import { SelectInput } from '../reusable-components/SelectInput';
import { useCompanySearch } from '../hooks/use-company-search';
import { MainComponentProps } from '../reusable-components/RouterComponent';

export const CompaniesPage: React.FC<MainComponentProps> = ({
  skipTokenCheck = false,
}) => {
  const [companyName, setCompanyName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [limitRecords, setLimitRecords] = useState<number>(10);
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const { companies, queryInProgress, searchCompanies, deleteCompany } =
    useCompanySearch(token, companyName, companyId, limitRecords);

  // define column titles and their ordering on the table
  const companyFieldDefs: Record<string, ColumnDefinition> = {
    companyId: { title: 'Company Id', index: 0 },
    name: { title: 'Company Name', index: 1 },
    street: { title: 'Street', index: 2 },
    houseNumber: { title: 'Street Number', index: 3 },
    city: { title: 'City', index: 4 },
    zipCode: { title: 'Postal Code', index: 5 },
  };

  const getPk = (row: CompanySearchResult) => row.companyPk;

  const checkSearchCondition = (): boolean => {
    return !queryInProgress && isAnyFieldFilled();
  };

  const isAnyFieldFilled = (): boolean => {
    return (
      (isBlank(companyId) && !isBlank(companyName)) ||
      (isNumeric(companyId) && isBlank(companyName))
    );
  };

  const pageJSX: JSX.Element = (
    <div className="md:container md:mx-auto">
      <div className="flex flex-row space-x-4">
        <TextInput
          label={'Company Name:'}
          value={companyName}
          placeholder="Any part of the company name here"
          data_testid="company-name-input"
          onChange={(e) => {
            setCompanyName(e.target.value);
            setCompanyId('');
          }}
          onEnterKeyPressed={() => checkSearchCondition() && searchCompanies()}
          width="w-1/3"
        />
        <TextInput
          label={'Company Id:'}
          value={companyId}
          placeholder="Number here"
          data_testid="company-id-input"
          onChange={(e) => {
            setCompanyId(e.target.value);
            setCompanyName('');
          }}
          onEnterKeyPressed={() => checkSearchCondition() && searchCompanies()}
          validation={isNumeric}
          errorMessage={'Company Id must be a number'}
          width="w-1/6"
        />
        <SelectInput
          label="Limit records:"
          value={limitRecords}
          onChange={(e) => setLimitRecords(parseInt(e.target.value))}
          valuesAndOptions={[
            { value: 10, option: '10' },
            { value: 20, option: '20' },
            { value: 30, option: '30' },
            { value: 50, option: '50' },
            { value: 100, option: '100' },
            { value: 200, option: '200' },
            { value: 500, option: '500' },
            { value: 1000, option: '1000' },
          ]}
          width="w-1/8"
        />
        <div className="w-1/3 pt-9">
          <div className="flex flex-row space-x-2">
            <button
              className="btn btn-primary w-40"
              onClick={searchCompanies}
              disabled={
                queryInProgress || !isTokenValid(token) || !isAnyFieldFilled()
              }
            >
              Search
            </button>
            {queryInProgress && (
              <span className="loading loading-spinner loading-lg"></span>
            )}
          </div>
          {/* <p className="mt-2"></p> */}
        </div>
      </div>

      <div className="flex-auto w-full max-w-7xl">
        {companies && companies.length > 0 ? (
          <PaginatedTable
            itemsPerPage={5}
            items={companies}
            columnDefs={companyFieldDefs}
            getPkOfRow={getPk}
            deleteCallback={deleteCompany}
          />
        ) : (
          !queryInProgress && (
            <h1 className="flex py-10 px-80">No items found</h1>
          )
        )}
      </div>
      <div className="max-w-xs py-4 flex">
        <Link
          data-testid="add-new-company-link"
          to="/add-company"
          className="btn btn-primary w-80"
        >
          Add New Company
        </Link>
      </div>
    </div>
  );

  if (skipTokenCheck) return pageJSX;
  else
    return checkTokenAndRenderCode(token, pageJSX, setCurrentRoute, navigate);
};
