import React, { useContext } from 'react';
import { checkTokenAndRenderCode } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../reusable-components/AuthContext';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { useAddNewCompany } from '../hooks/add-new-company';

export const AddCompanyPage = () => {
  const [error, setError] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');
  const [companyStreet, setCompanyStreet] = React.useState<string>('');
  const [houseNumber, setHouseNumber] = React.useState<string>('');
  const [zipCode, setZipCode] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const { queryInProgress, handleAddCompany } = useAddNewCompany(
    token,
    setError,
    companyName,
    companyStreet,
    houseNumber,
    zipCode,
    city,
  );

  const pageJSX: JSX.Element = (
    <div className="md:container md:mx-auto px-10">
      <h1>Add New Company</h1>
      <label className="flex-auto form-control max-w">
        <div className="label">
          <span className="label-text">Company Name:</span>
        </div>
        <input
          type="text"
          name="name"
          placeholder="Company Name"
          className="input input-bordered input-primary w-full max-w-xs"
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
        />
      </label>

      <label className="flex-auto form-control max-w">
        <div className="label">
          <span className="label-text">Company Street:</span>
        </div>
        <input
          type="text"
          name="street"
          placeholder="Company Street"
          className="input input-bordered input-primary w-full max-w-xs"
          onChange={(e) => setCompanyStreet(e.target.value)}
          value={companyStreet}
        />
      </label>

      <label className="flex-auto form-control max-w">
        <div className="label">
          <span className="label-text">Street Number:</span>
        </div>
        <input
          type="text"
          name="houseNumber"
          placeholder="Street Number"
          className="input input-bordered input-primary w-full max-w-xs"
          onChange={(e) => setHouseNumber(e.target.value)}
          value={houseNumber}
        />
      </label>

      <label className="flex-auto form-control max-w">
        <div className="label">
          <span className="label-text">ZIP Code:</span>
        </div>
        <input
          type="text"
          name="zipCode"
          placeholder="Zip Code"
          className="input input-bordered input-primary w-full max-w-xs"
          onChange={(e) => setZipCode(e.target.value)}
          value={zipCode}
        />
      </label>

      <label className="flex-auto form-control max-w">
        <div className="label">
          <span className="label-text">City:</span>
        </div>
        <input
          type="text"
          name="city"
          placeholder="City"
          className="input input-bordered input-primary w-full max-w-xs"
          onChange={(e) => setCity(e.target.value)}
          value={city}
        />
      </label>

      <div className="max-w-xs py-4 flex justify-center">
        <button
          className="w-full btn btn-primary w-40"
          onClick={handleAddCompany}
          data-testid="add-company-button"
        >
          Add Company
        </button>
      </div>
      <div className="max-w-xs flex justify-center">
        {queryInProgress ? (
          <div className="loading loading-spinner loading-lg w-10"></div>
        ) : (
          <div className="text-red-500">{error}</div>
        )}
      </div>
    </div>
  );
  return checkTokenAndRenderCode(token, pageJSX, setCurrentRoute, navigate);
};
