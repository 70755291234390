import { useContext, useState } from 'react';
import { postSearchJob, postDeleteJob } from '../services/job-api';
import { JobSearchPayload } from '../models/job/JobSearchPayload';
import { JobSearchResult } from '../models/job/JobSearchResult';
import { DeleteJobPayload } from '../models/job/DeleteJobPayload';
import { StringOperationResponse } from '../models/base/StringOperationResponse';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { isAxiosError } from 'axios';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { sessionExpiredMessage } from '../services/api-main';

export const couldNotDeleteJob = 'Could not delete job. ';
export const seeBrowserConsoleMessage = 'See browser console for more details.';
export const errorOnSearchJobsMessage =
  'An error occurred while searching for jobs.';

export const useJobSearch = (
  token: string,
  dateRangeValue: DateValueType,
  userName: string,
  userId: string,
  companyName: string,
  companyId: string,
  jobState: string,
  limitRecords: number,
) => {
  const [jobs, setJobs] = useState<JobSearchResult[]>([]);
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const searchJobs = async () => {
    try {
      setJobs([]);
      const startDate = dateRangeValue?.startDate?.toJSON().split('T')[0] || '';
      const endDate = dateRangeValue?.endDate?.toJSON().split('T')[0] || '';

      const payload: JobSearchPayload = {
        token,
        fromDate: startDate,
        toDate: endDate,
        username: userName,
        userId,
        companyName,
        companyId,
        state: jobState,
        limit: limitRecords,
      };

      setQueryInProgress(true);
      const result: JobSearchResult[] = await postSearchJob(payload);
      setQueryInProgress(false);
      setJobs(result);
    } catch (error) {
      setQueryInProgress(false);
      if (isAxiosError(error) && error.response?.status === 403) {
        alert(sessionExpiredMessage);
        NavigateToPageRoute('/', setCurrentRoute, navigate);
      } else {
        alert(errorOnSearchJobsMessage);
      }
    }
  };

  const deleteJob = async (token: string, jobId: string) => {
    if (!confirm(`Are you sure you want to delete this job?`)) {
      return;
    }
    const payload: DeleteJobPayload = { token, jobId };
    const response: StringOperationResponse = await postDeleteJob(payload);
    if (response.success) {
      const itemsFiltered = jobs.filter((c) => c.jobId.toString() != jobId);
      setJobs(itemsFiltered);
    } else {
      const title = couldNotDeleteJob;
      if (response.userMessage) {
        alert(title + response.userMessage);
      } else {
        alert(title + seeBrowserConsoleMessage);
      }
    }
  };

  return { jobs, queryInProgress, searchJobs, deleteJob };
};
