import { OptionElementType } from '../reusable-components/GenericSearchableSelect';
import { GetUsersResponse } from '../models/user/GetUsersResponse';
import { getAllUsers } from '../services/user-api';
import { postSearchCompany } from '../services/company-api';
import { CompanySearchResult } from '../models/company/CompanySearchResult';

export const useGetAllOptions = (token: string) => {
  const fileTypeOptions: OptionElementType[] = [
    { value: 'csv', title: 'CSV', description: 'Comma Separated Values' },
    { value: 'json', title: 'JSON', description: 'JavaScript Object Notation' },
    { value: 'xml', title: 'XML', description: 'Extensible Markup Language' },
    { value: 'pdf', title: 'PDF', description: 'Portable Document Format' },
  ];

  const invoiceTypeOptions: OptionElementType[] = [
    { value: 'GENER', title: 'Generic', description: 'Generic Invoice' },
    { value: 'EPLUS', title: 'Eplus', description: 'Eplus Invoice' },
    {
      value: 'EPLUC',
      title: 'Eplus Corporate',
      description: 'Eplus Corporate Invoice',
    },
    { value: 'EPLUA', title: 'Eplus ASC', description: 'Eplus ASC Invoice' },
    { value: 'BASE', title: 'Base', description: 'Base Invoice' },
    { value: 'SIMYO', title: 'Simyo', description: 'Simyo Invoice' },
  ];

  const networkProviderOptions: OptionElementType[] = [
    { value: 'DRSRV', title: 'Directory Service Provider', description: '' },
    { value: 'D-VOD', title: 'Vodafone Deutschland', description: '' },
    { value: 'D-TMO', title: 'T Mobile Deutschland', description: '' },
    { value: 'D-EPL', title: 'E-Plus', description: '' },
    { value: 'D-O2', title: 'O2 Deutschland', description: '' },
    { value: 'D-FIXEDNET', title: 'German FixedNet', description: '' },
    { value: 'D-FRE', title: 'German Toll Free Network', description: '' },
    { value: 'D-SKYP', title: 'eSkyper Pager Network', description: '' },
    { value: 'D-CIT', title: 'Cityruf', description: '' },
    { value: 'D-SPE', title: 'German Special Services', description: '' },
    { value: 'UKTMO', title: 'T Mobile UK', description: '' },
    { value: 'UKVOD', title: 'Vodafone UK', description: '' },
    { value: 'UKORA', title: 'Orange UK', description: '' },
    { value: 'UK02', title: 'O2 UK', description: '' },
    { value: 'UK3', title: 'Three UK', description: '' },
    { value: 'ROAM', title: 'Roaming Service Provider', description: '' },
  ];

  const serviceProviderOptions: OptionElementType[] = [
    { value: 'TMOB', title: 'T-Mobile', description: '' },
    { value: 'VODA', title: 'Vodafone', description: '' },
    { value: 'E+', title: 'E-Plus', description: '' },
    { value: 'BASE', title: 'BASE', description: '' },
    { value: 'SIMYO', title: 'Simyo', description: '' },
    { value: 'O2', title: 'O2 Germany', description: '' },
    { value: 'MC', title: 'Mobilcom', description: '' },
    { value: 'MCD', title: 'Mobilcom debitel', description: '' },
    { value: 'FN', title: 'Freenet', description: '' },
    { value: 'TALKL', title: 'Talkline', description: '' },
    { value: 'DEBIT', title: 'Debitel', description: '' },
    { value: 'CONGS', title: 'Congstar', description: '' },
    { value: 'FONIC', title: 'Fonic', description: '' },
    { value: 'TCHIB', title: 'Tchibo Mobil', description: '' },
  ];

  const getAllUsersOptions = async () => {
    const users: GetUsersResponse[] = await getAllUsers(token);

    return users
      .filter((u) => u.id !== 0)
      .sort((a, b) => {
        const titleComparison = a.username
          .toLowerCase()
          .localeCompare(b.username.toLowerCase());
        if (titleComparison !== 0) {
          return titleComparison;
        } else return a.id - b.id;
      })
      .map((user: GetUsersResponse) => ({
        value: user.id.toString(),
        title: user.username,
        description: user.id.toString(), // Not needed here
      })) as OptionElementType[];
  };

  const getAllCompaniesOptions = async () => {
    const companies: CompanySearchResult[] = await postSearchCompany({
      token,
      companyId: '',
      name: '%',
      limit: 100000, // upper limit 100K.
    });

    return companies
      .filter((c) => c.companyId !== 0)
      .sort((a, b) => {
        const nameComparison = a.name
          .toLowerCase()
          .localeCompare(b.name.toLowerCase());
        if (nameComparison !== 0) {
          return nameComparison;
        } else return a.companyId - b.companyId;
      })
      .map((c: CompanySearchResult) => ({
        value: c.companyId?.toString(),
        title: c.name,
        description: c.companyId?.toString(),
      })) as OptionElementType[];
  };
  return {
    fileTypeOptions,
    invoiceTypeOptions,
    serviceProviderOptions,
    networkProviderOptions,
    getAllUsersOptions,
    getAllCompaniesOptions,
  };
};
