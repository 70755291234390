import axios, { AxiosError, AxiosInstance } from 'axios';
import qs from 'qs';
import { PayloadBase } from '../models/base/PayloadBase';
import {
  SUCCESS_RESPONSE,
  StringOperationResponse,
} from '../models/base/StringOperationResponse';

export const sessionExpiredMessage =
  'The user session has expired. Please log in again.';

export const seeBrowserConsoleMessage = 'See browser console for more details.';

export const unexpectedStatusCodeMessage = (statusCode: number) => {
  return `Unexpected status code: ${statusCode}`;
};

export const sendFormDataClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

export const sendJsonPayloadClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

export const errorMessages: Record<number, string> = {
  404: 'Element not found.',
  403: 'Session has expired. Please log in again.',
  500: 'Server error.',
  504: 'Server is not responding. Please try again later.',
};

const knownErrorCodes = Object.keys(errorMessages).map((k) => parseInt(k));

export const callPostStringOperationEndpoint = async ({
  endpointPath,
  payload,
  client,
  stringifyPayload = true,
}: {
  endpointPath: string;
  payload: PayloadBase;
  client: AxiosInstance;
  stringifyPayload?: boolean;
}): Promise<StringOperationResponse> => {
  try {
    const data = stringifyPayload ? qs.stringify(payload) : payload;
    const response = await client.post(endpointPath, data);

    if (response?.data === SUCCESS_RESPONSE) {
      return { success: true };
    } else {
      throw new Error(`Unexpected response. Response: '${response?.data}'`);
    }
  } catch (error) {
    if (!isAxiosError(error)) {
      return { success: false, userMessage: `Unexpected error: ${error}` };
    }
    // else is an axios error
    const myErrorCode: number = error.response?.status || 0;
    let userMessage = unexpectedStatusCodeMessage(myErrorCode);
    if (knownErrorCodes.includes(myErrorCode)) {
      userMessage = errorMessages[error.response?.status as number];
    }
    const detailedErrorMessage = ` Error in endpoint: ${endpointPath}: ${error}.`;
    console.error(userMessage + detailedErrorMessage);
    return { success: false, userMessage };
  }
};
