import React, { useState, useContext } from 'react';
import { ColumnDefinition } from '../reusable-components/GenericTable';
import { PaginatedTable } from '../reusable-components/PaginatedTable';
import { AuthContext } from '../reusable-components/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { DateRangePickerInput } from '../reusable-components/DateRangePickerInput';
import { TextInput } from '../reusable-components/TextInput';
import { SelectInput } from '../reusable-components/SelectInput';
import { JobSearchResult } from '../models/job/JobSearchResult';
import { normalizeDateRange } from '../utils/date-functions';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { useJobSearch } from '../hooks/use-job-search';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import {
  checkTokenAndRenderCode,
  isTokenValid,
} from '../utils/common-functions';
import { isBlank, isNumeric } from '../utils/common-functions';
import { MainComponentProps } from '../reusable-components/RouterComponent';

export const RatingJobsPage: React.FC<MainComponentProps> = ({
  skipTokenCheck = false,
}) => {
  const [companyName, setCompanyName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [jobState, setJobState] = useState<string>('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const [limitRecords, setLimitRecords] = useState<number>(10);

  const currentDate = new Date();

  const tomorrowDate = () => {
    const today = currentDate;
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };

  // date range picker
  const [dateRangeValue, setDateRangeValue] = useState<DateValueType>({
    startDate: currentDate,
    endDate: tomorrowDate(),
  });

  const { jobs, queryInProgress, searchJobs, deleteJob } = useJobSearch(
    token,
    dateRangeValue,
    userName,
    userId,
    companyName,
    companyId,
    jobState,
    limitRecords,
  );

  const handleDownloadZipFile = async (row: JobSearchResult) => {
    const url = `http://localhost:8080/ItellityWebServices/output/${row.customerId}/${row.zipFileName}`;
    window.open(url, '_blank');
  };

  // define column titles and their ordering on the table
  const jobFieldDefs: Record<string, ColumnDefinition> = {
    jobId: { title: 'Job Id', index: 0 },
    customerName: { title: 'Customer', index: 1 },
    executionDate: { title: 'Execution Date', index: 2 },
    contractAmount: { title: 'Contract Amount', index: 3 },
    jobState: { title: 'Job State', index: 4 },
  };

  const checkSearchCondition = (): boolean => {
    return !queryInProgress && allFieldsValid() && isAnyFieldFilled();
  };

  const allFieldsValid = (): boolean => {
    return (
      (isNumeric(companyId) || isBlank(companyId)) &&
      (isNumeric(userId) || isBlank(userId))
    );
  };

  const isAnyFieldFilled = (): boolean => {
    return (
      !isBlank(companyName) ||
      isNumeric(companyId) ||
      !isBlank(userName) ||
      isNumeric(userId) ||
      !isBlank(jobState) ||
      dateRangeValue?.startDate !== null ||
      dateRangeValue?.endDate !== null
    );
  };

  const getJobId = (row: JobSearchResult) => row.jobId.toString();

  const pageJSX: JSX.Element = (
    <div className="md:container md:mx-auto">
      <div className="flex space-x-4">
        <TextInput
          label="Company Name:"
          value={companyName}
          placeholder="Any part of the company name here"
          data_testid="company-name-input"
          onChange={(e) => {
            setCompanyId('');
            setCompanyName(e.target.value);
            setUserId('');
            setUserName('');
          }}
          onEnterKeyPressed={() => checkSearchCondition() && searchJobs()}
          width="w-1/4"
        />
        <TextInput
          label="Company Id:"
          value={companyId}
          placeholder="Number here"
          data_testid="company-id-input"
          onChange={(e) => {
            setCompanyId(e.target.value);
            setCompanyName('');
            setUserId('');
            setUserName('');
          }}
          onEnterKeyPressed={() => checkSearchCondition() && searchJobs()}
          validation={isNumeric}
          errorMessage="Company Id must be a number"
          width="w-1/8"
        />

        <DateRangePickerInput
          label="Date Range:"
          value={dateRangeValue}
          onChange={(newValue: DateValueType) => {
            const newDateRange = normalizeDateRange(
              newValue?.startDate ?? null,
              newValue?.endDate ?? null,
            );
            setDateRangeValue(newDateRange);
          }}
        />

        <SelectInput
          label="Limit records:"
          value={limitRecords}
          onChange={(e) => setLimitRecords(parseInt(e.target.value))}
          valuesAndOptions={[
            { value: 10, option: '10' },
            { value: 20, option: '20' },
            { value: 30, option: '30' },
            { value: 50, option: '50' },
            { value: 100, option: '100' },
            { value: 200, option: '200' },
            { value: 500, option: '500' },
            { value: 1000, option: '1000' },
          ]}
          width="w-1/8"
        />
      </div>
      <div className="md:container md:mx-auto">
        <div className="flex space-x-4">
          <TextInput
            label="Username:"
            value={userName}
            placeholder="The user name that started the job"
            onChange={(e) => {
              setCompanyId('');
              setCompanyName('');
              setUserId('');
              setUserName(e.target.value);
            }}
            onEnterKeyPressed={() => checkSearchCondition() && searchJobs()}
          />
          <TextInput
            label="User Id:"
            value={userId}
            placeholder="Number here"
            onChange={(e) => {
              setCompanyId('');
              setCompanyName('');
              setUserId(e.target.value);
              setUserName('');
            }}
            onEnterKeyPressed={() => checkSearchCondition() && searchJobs()}
            validation={isNumeric}
            errorMessage="User Id must be a number"
            width="w-1/8"
          />

          <SelectInput
            label="Job State:"
            value={jobState}
            onChange={(e) => setJobState(e.target.value)}
            valuesAndOptions={[
              { value: '', option: 'Please select a state' },
              { value: 'DONE', option: 'Done' },
              { value: 'START', option: 'Started' },
              { value: 'PROG', option: 'In Progress' },
              { value: 'ERROR', option: 'Error' },
            ]}
            width="w-1/6"
          />
          <div className="w-80 pt-9">
            <div className="flex flex-row space-x-2 px-10">
              <button
                className="btn btn-primary w-40"
                onClick={searchJobs}
                disabled={
                  queryInProgress ||
                  !isTokenValid(token) ||
                  !allFieldsValid() ||
                  !isAnyFieldFilled()
                }
              >
                Search
              </button>
              {queryInProgress && (
                <span className="loading loading-spinner loading-lg"></span>
              )}
            </div>
            <p className="mt-2"></p>
          </div>
        </div>
      </div>

      <div className="flex-auto w-full max-w-7xl">
        {jobs && jobs.length > 0 ? (
          <PaginatedTable
            itemsPerPage={5}
            items={jobs}
            columnDefs={jobFieldDefs}
            getPkOfRow={getJobId}
            deleteCallback={deleteJob}
            downloadLink={handleDownloadZipFile}
          />
        ) : (
          !queryInProgress && (
            <h1 className="flex py-10 px-80">No items found</h1>
          )
        )}
      </div>
      <div className="max-w-xs py-4 flex">
        <Link
          to="/add-job"
          data-testid="add-job-link"
          className="btn btn-primary w-80"
        >
          Add New Job
        </Link>
      </div>
    </div>
  );

  if (skipTokenCheck) {
    return pageJSX;
  } else {
    return checkTokenAndRenderCode(token, pageJSX, setCurrentRoute, navigate);
  }
};
