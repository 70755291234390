import { useContext } from 'react';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AddCompanyPayload } from '../models/company/AddCompanyPayload';
import { postAddCompany } from '../services/company-api';
import React from 'react';

export const useAddNewCompany = (
  token: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  companyName: string,
  companyStreet: string,
  houseNumber: string,
  zipCode: string,
  city: string,
) => {
  const [queryInProgress, setQueryInProgress] = React.useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const handleAddCompany = async () => {
    setQueryInProgress(true);
    const payload: AddCompanyPayload = {
      token: token,
      name: companyName,
      street: companyStreet,
      houseNumber: houseNumber,
      zipCode: zipCode,
      city: city,
    };
    const response = await postAddCompany(payload);
    setQueryInProgress(false);

    if (response.success) {
      alert('Company added successfully.');
      NavigateToPageRoute('/companies', setCurrentRoute, navigate);
    } else {
      setError(
        response.userMessage ?? 'An error occurred while adding company.',
      );
    }
  };

  return { queryInProgress, handleAddCompany };
};
