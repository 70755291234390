import qs from 'qs';
import {
  sendFormDataClient,
  callPostStringOperationEndpoint,
} from './api-main';
import { AddCompanyPayload } from '../models/company/AddCompanyPayload';
import { DeleteCompanyPayload } from '../models/company/DeleteCompanyPayload';
import { StringOperationResponse } from '../models/base/StringOperationResponse';
import { CompanySearchPayload } from '../models/company/CompanySearchPayload';
import { CompanySearchResult } from '../models/company/CompanySearchResult';

export const postSearchCompany = async (
  payload: CompanySearchPayload,
): Promise<CompanySearchResult[]> => {
  try {
    // Convert the parameters to URL-encoded string
    const parameters = qs.stringify(payload);
    const { data } = await sendFormDataClient.post(
      '/customerCompany/search',
      parameters,
    );

    return data;
  } catch (error) {
    console.error('Error searching for company:', error);
    throw error;
  }
};

export const postAddCompany = async (
  payload: AddCompanyPayload,
): Promise<StringOperationResponse> => {
  return await callPostStringOperationEndpoint({
    endpointPath: '/customerCompany/addCompany',
    payload,
    client: sendFormDataClient,
  });
};

export const postDeleteCompany = async (
  payload: DeleteCompanyPayload,
): Promise<StringOperationResponse> => {
  return await callPostStringOperationEndpoint({
    endpointPath: '/customerCompany/deleteCompany',
    payload,
    client: sendFormDataClient,
  });
};
