import { useContext, useState } from 'react';
import { StringOperationResponse } from '../models/base/StringOperationResponse';
import { isAxiosError } from 'axios';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { DeleteCompanyPayload } from '../models/company/DeleteCompanyPayload';
import { postDeleteCompany, postSearchCompany } from '../services/company-api';
import { CompanySearchResult } from '../models/company/CompanySearchResult';
import { CompanySearchPayload } from '../models/company/CompanySearchPayload';
import {
  seeBrowserConsoleMessage,
  sessionExpiredMessage,
} from '../services/api-main';

export const couldNotDeleteCompanyMessage = 'Could not delete company. ';

export const errorOnSearchCompaniesMessage =
  'An error occurred while searching for companies.';

export const useCompanySearch = (
  token: string,
  companyName: string,
  companyId: string,
  limitRecords: number,
) => {
  const [companies, setCompanies] = useState<CompanySearchResult[]>([]);
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const searchCompanies = async () => {
    try {
      setCompanies([]);
      const payload: CompanySearchPayload = {
        token: token,
        name: companyName,
        companyId: companyId,
        limit: limitRecords,
      };

      setQueryInProgress(true);
      const result: CompanySearchResult[] = await postSearchCompany(payload);
      setQueryInProgress(false);
      setCompanies(result);
    } catch (error) {
      setQueryInProgress(false);
      if (isAxiosError(error) && error.response?.status === 403) {
        alert(sessionExpiredMessage);
        NavigateToPageRoute('/', setCurrentRoute, navigate);
      } else {
        alert(errorOnSearchCompaniesMessage);
      }
    }
  };

  const deleteCompany = async (token: string, pk: string) => {
    if (!confirm(`Are you sure you want to delete this company?`)) {
      return;
    }
    const payload: DeleteCompanyPayload = { token, companyPk: pk };
    const response: StringOperationResponse = await postDeleteCompany(payload);
    if (response.success) {
      const itemsFiltered = companies.filter((c) => c.companyPk !== pk);
      setCompanies(itemsFiltered);
    } else {
      const title = couldNotDeleteCompanyMessage;
      if (response.userMessage) {
        alert(title + response.userMessage);
      } else {
        alert(title + seeBrowserConsoleMessage);
      }
    }
  };

  return { companies, queryInProgress, searchCompanies, deleteCompany };
};
