import React from 'react';
import '../App.css';
import { NavBar } from './NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RatingJobsPage } from '../pages/RatingJobsPage';
import { CompaniesPage } from '../pages/CompaniesPage';
import { LoginPage } from '../pages/LoginPage';
import { AddCompanyPage } from '../pages/AddCompanyPage';
import { AddJobPage } from '../pages/AddJobPage';

export interface MainComponentProps {
  skipTokenCheck?: boolean;
}

export interface Page {
  route: string;
  title: string;
  navBar: boolean; // whether to show in the navbar
  component: React.FC<MainComponentProps>;
}

const pageMap: Record<string, Page> = {
  LOGIN: {
    route: '/',
    title: 'Login Page',
    navBar: true,
    component: LoginPage,
  },
  COMPANIES: {
    route: '/companies',
    title: 'Companies',
    navBar: true,
    component: CompaniesPage,
  },
  ADD_COMPANY: {
    route: '/add-company',
    title: 'Add Company',
    navBar: false,
    component: AddCompanyPage,
  },
  RATING_JOBS: {
    route: '/rating-jobs',
    title: 'Rating Jobs',
    navBar: true,
    component: RatingJobsPage,
  },
  ADD_JOB: {
    route: '/add-job',
    title: 'Add Job',
    navBar: false,
    component: AddJobPage,
  },
};

export const pages: Page[] = Object.entries(pageMap).map(([type, page]) => ({
  type,
  ...page,
}));

export const RouterComponent: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        {pages.map((page: Page) => {
          return (
            <Route
              key={page.route}
              path={page.route}
              element={<page.component />}
            />
          );
        })}
      </Routes>
    </Router>
  );
};
