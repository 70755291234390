import React from 'react';
import { createContext, useState, ReactNode } from 'react';

interface CurrentPageContextProps {
  currentRoute: string;
  setCurrentRoute: (route: string) => void;
}

const initialContext: CurrentPageContextProps = {
  currentRoute: '/', // Default to the login page
  setCurrentRoute: () => {
    return;
  },
};

export const CurrentPageContext =
  createContext<CurrentPageContextProps>(initialContext);

export const CurrentPageProvider = ({ children }: { children: ReactNode }) => {
  const [currentRoute, setCurrentRoute] = useState<string>(
    initialContext.currentRoute,
  );

  return (
    <CurrentPageContext.Provider value={{ currentRoute, setCurrentRoute }}>
      {children}
    </CurrentPageContext.Provider>
  );
};
