/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
  GenericTable,
  GenericTableProps,
  ColumnDefinition,
  RowType,
} from '../reusable-components/GenericTable';

export type PaginatedTableProps<R extends RowType> = {
  itemsPerPage: number;
  items: R[];
  columnDefs: Record<string, ColumnDefinition>;
  getPkOfRow: (row: R) => string;
  deleteCallback?: (token: string, pk: string) => Promise<void>;
  downloadLink?: (row: R) => Promise<void>;
};

export function PaginatedTable<R extends RowType>(
  props: PaginatedTableProps<R>,
): JSX.Element {
  const {
    itemsPerPage,
    items,
    columnDefs,
    getPkOfRow,
    deleteCallback,
    downloadLink,
  } = props;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const GenericTableComponent: React.FC<GenericTableProps<R>> =
    GenericTable<R>(columnDefs);

  return (
    <>
      <GenericTableComponent
        rows={currentItems}
        getPkOfRow={getPkOfRow}
        deleteCallback={deleteCallback}
        downloadLink={downloadLink}
      />
      <div className="flex flex-row space-x-4">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          containerClassName="join pt-4"
          pageLinkClassName="join-item btn"
          previousLinkClassName="join-item btn"
          nextLinkClassName="join-item btn"
          activeLinkClassName="btn-active"
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
        <h2 className="flex-auto w-60 pt-8">Found {items.length} items.</h2>
      </div>
    </>
  );
}
