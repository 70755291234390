import React, { useState, useContext } from 'react';
import { getUserLogin } from '../services/user-api';
import { AuthContext } from '../reusable-components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { TextInput } from '../reusable-components/TextInput';

export const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const { setToken } = authContext;
  const [userName, setUserName] = useState<string>('erik');
  const [password, setPassword] = useState<string>('password');
  const [loginError, setLoginError] = useState<string>('');
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const handleLogin = async (userName: string, password: string) => {
    setQueryInProgress(true);
    setLoginError('');
    const result = await getUserLogin(userName, password);
    setQueryInProgress(false);

    if (result.success) {
      setToken(result.token);
      setLoginError('');
      NavigateToPageRoute('/companies', setCurrentRoute, navigate);
    } else {
      setToken('');
      setLoginError(result.errorMessage ?? 'Unknown error while logging in');
    }
  };

  return (
    <div className="md:container md:mx-auto px-32">
      <TextInput
        label="Username:"
        value={userName}
        placeholder="Enter user name"
        onChange={(e) => setUserName(e.target.value)}
        required={true}
        errorMessage="User name is required"
      />

      <TextInput
        label="Password:"
        value={password}
        placeholder="Enter password"
        onChange={(e) => setPassword(e.target.value)}
        onEnterKeyPressed={() => password && handleLogin(userName, password)}
        isPassword={true}
        required={true}
        errorMessage="Password is required"
      />

      <div className="max-w-xs py-4 flex justify-center">
        <button
          data-testid="login-button"
          className="w-full btn btn-primary w-40"
          onClick={() => handleLogin(userName, password)}
          disabled={!userName || !password || queryInProgress}
        >
          Login
        </button>
      </div>
      <div className="max-w-xs flex justify-center">
        <div className="text-red-500">{loginError}</div>
        {queryInProgress && (
          <div className="loading loading-spinner loading-lg w-10"></div>
        )}
      </div>
    </div>
  );
};
