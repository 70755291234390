import React, { useContext } from 'react';
import { pages } from './RouterComponent';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from '../icons/UserIcon';
import { AuthContext } from '../reusable-components/AuthContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { CurrentPageContext } from './CurrentPageContext';

export const NavBar: React.FC = () => {
  const currentRouteContext = useContext(CurrentPageContext);
  const { currentRoute, setCurrentRoute } = currentRouteContext;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { setToken } = authContext;

  const handleLogout = () => {
    setToken('invalid_token');
    NavigateToPageRoute('/', setCurrentRoute, navigate);
    return;
  };

  return (
    <>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          {pages
            .filter((p) => p.navBar)
            .map((page) => {
              const isSelected = page.route === currentRoute;
              return (
                <button
                  key={page.route}
                  className={
                    'btn btn-ghost text-xl' + (isSelected ? ' btn-outline' : '')
                  }
                  onClick={() =>
                    NavigateToPageRoute(page.route, setCurrentRoute, navigate)
                  }
                >
                  {page.title}
                </button>
              );
            })}
        </div>
        <div className="flex-none gap-2">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle avatar"
            >
              <div className="w-10 rounded-full">
                <UserIcon />
              </div>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
            >
              <li>
                <a href="/" className="justify-between">
                  Profile
                  <span className="badge">New</span>
                </a>
              </li>
              <li>
                <a href="/">Settings</a>
              </li>
              <li>
                <a href="/" data-testid="logout-link" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
