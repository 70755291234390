export const normalizeDateRange = (
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (!startDate || !endDate) {
    return {
      startDate: null,
      endDate: null,
    };
  } else {
    return {
      startDate: normalizeToNoon(startDate),
      endDate: normalizeToNoon(endDate),
    };
  }
};

export function normalizeToNoon(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0); // Set hour to 12:00:00.000
  return newDate;
}
