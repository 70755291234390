export const SUCCESS_RESPONSE = 'Success';

/*
  This class is being used for all the BE operations
   that return a 'Success' string in the response.
  
  If the response is not an OK "Success" string, then
   the status code is used to determine the user message.
*/
export interface StringOperationResponse {
  success: boolean;
  userMessage?: string;
}
