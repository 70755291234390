import { useContext, useState } from 'react';
import { postAddJob } from '../services/job-api';
import { CurrentPageContext } from '../reusable-components/CurrentPageContext';
import { NavigateToPageRoute } from '../utils/common-functions';
import { useNavigate } from 'react-router-dom';
import { AddJobPayload } from '../models/job/AddJobPayload';
import { OptionElementType } from '../reusable-components/GenericSearchableSelect';

const BASE_FOLDER_PATH = '/usr/local/tomcat/webapps/ItellityWebServices';

export const useAddNewJob = (
  token: string,
  setError: React.Dispatch<React.SetStateAction<string>>,
  fileType: OptionElementType,
  invoiceType: OptionElementType,
  networkProvider: OptionElementType,
  serviceProvider: OptionElementType,
  company?: OptionElementType,
  user?: OptionElementType,
) => {
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const currentPageContext = useContext(CurrentPageContext);
  const { setCurrentRoute } = currentPageContext;
  const navigate = useNavigate();

  const addNewJob = async () => {
    setQueryInProgress(true);
    const customerCompanyId = company?.value ? parseInt(company.value) : 0;

    const payload: AddJobPayload = {
      token: token,
      inputDirectory: `${BASE_FOLDER_PATH}/input`,
      outputDirectory: `${BASE_FOLDER_PATH}/output/${customerCompanyId}`,
      fileType: fileType?.value,
      invoiceType: invoiceType.value,
      networkProvider: networkProvider.value,
      serviceProvider: serviceProvider.value,
      clientName: company?.title ?? '',
      customerCompanyId,
      userId: user?.value.toString() ?? '0',
    };
    const response = await postAddJob(payload);
    setQueryInProgress(false);

    if (response.success) {
      alert('Job added successfully.');
      NavigateToPageRoute('/rating-jobs', setCurrentRoute, navigate);
    } else {
      setError(
        response.userMessage ?? 'An error occurred while adding the job.',
      );
    }
  };
  return { queryInProgress, setQueryInProgress, addNewJob };
};
